import { combineEpics } from 'redux-observable'
import { changedTo } from 'redux-routable'
import { distinctUntilChanged, filter, map, pluck } from 'rxjs/operators'
import {
  fetchAdmissionCensus,
  fetchDischargeCensus,
  fetchERCensus,
  fetchInpatientCensus,
  fetchSNFCensus,
} from '../data/census'
import {
  CASE_MANAGER_DASHBOARD_DISCHARGE_CENSUS,
  CASE_MANAGER_DASHBOARD_ER_CENSUS,
  CASE_MANAGER_DASHBOARD_HOME,
  CASE_MANAGER_DASHBOARD_INPATIENT_CENSUS,
  CASE_MANAGER_DASHBOARD_SNF_CENSUS,
} from '../router'

const fetchCensus = (route, request) => action$ =>
  action$.pipe(
    filter(changedTo(route)),
    pluck('payload', 'params', 'cmId'),
    filter(userId => userId !== undefined),
    distinctUntilChanged(),
    map(request.requested)
  )

export default combineEpics(
  fetchCensus(CASE_MANAGER_DASHBOARD_DISCHARGE_CENSUS, fetchDischargeCensus),
  fetchCensus(CASE_MANAGER_DASHBOARD_ER_CENSUS, fetchERCensus),
  fetchCensus(CASE_MANAGER_DASHBOARD_HOME, fetchAdmissionCensus),
  fetchCensus(CASE_MANAGER_DASHBOARD_INPATIENT_CENSUS, fetchInpatientCensus),
  fetchCensus(CASE_MANAGER_DASHBOARD_SNF_CENSUS, fetchSNFCensus)
)
