import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Columns, { Column } from '~/apps/patientRecord/components/Columns'
import DevXGrid from '~/components/DevXGrid'
import DisplayDateTime from '~/components/DisplayDateTime'
import WithTooltip from '~/components/WithTooltip'
import { getPatientId } from '~/features/patientInfo'
import { Hidden, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  fetchPatientCareTeamHistory,
  getPatientCareTeamDetails,
  getPatientCareTeamHistory,
} from '../data/careTeams'
import CTMembers from './CTMembers'

const useStyles = makeStyles(({ palette, spacing }) => ({
  sectionHeader: {
    color: palette.primary.main,
    marginBottom: spacing(2),
  },
  topMarging: {
    marginTop: spacing(3),
  },
}))

const PatientCareTeam = () => {
  const classes = useStyles()
  const careTeamDetails = useSelector(getPatientCareTeamDetails)
  const dispatch = useDispatch()
  const patientId = useSelector(getPatientId)
  const history = useSelector(getPatientCareTeamHistory)
  const members = careTeamDetails && careTeamDetails.members

  const historyColumns = [
    { name: 'changeType', title: 'Change Type' },
    {
      name: 'fromDate',
      title: 'Date Of Change',
      displayComponent: DisplayDateTime,
    },
    { name: 'modifiedBy', title: 'Changed By' },
    {
      name: 'changeNotes',
      title: 'Change Notes',
      displayComponent: WithTooltip,
    },
  ]

  const compareTimestamps = (a, b) => {
    const aDate = Date.parse(a)
    const bDate = Date.parse(b)

    return aDate - bDate
  }

  const defaultSorting = [{ columnName: 'fromDate', direction: 'desc' }]

  const columnExtensions = [
    { columnName: 'fromDate', width: 220, compare: compareTimestamps },
    { columnName: 'changeType', width: 180 },
    { columnName: 'modifiedBy', width: 230 },
  ]

  useEffect(() => {
    patientId
      ? dispatch(fetchPatientCareTeamHistory.requested(patientId))
      : null
  }, [dispatch, patientId, careTeamDetails])

  return (
    <React.Fragment>
      {careTeamDetails ? (
        <React.Fragment>
          <Typography variant="h5" className={classes.sectionHeader}>
            {careTeamDetails.label}
          </Typography>
          <Columns>
            <Hidden smUp>
              <Column>
                <div>
                  <CTMembers
                    members={members}
                    careTeamType={careTeamDetails.type}
                  />
                </div>
              </Column>
            </Hidden>
            <Hidden xsDown>
              <Column>
                <div>
                  <CTMembers
                    members={members.slice(0, Math.ceil(members.length / 2))}
                    careTeamType={careTeamDetails.type}
                  />
                </div>
              </Column>
              <Column>
                <div>
                  <CTMembers
                    members={members.slice(Math.ceil(members.length / 2))}
                    careTeamType={careTeamDetails.type}
                  />
                </div>
              </Column>
            </Hidden>
          </Columns>
          <React.Fragment>
            <Typography
              variant="h5"
              className={`${classes.sectionHeader} ${classes.topMarging}`}
            >
              Patient CareTeam History
            </Typography>
            <Paper>
              <DevXGrid
                rows={history?.length ? history : []}
                columns={historyColumns}
                sortable
                filterable
                defaultSorting={defaultSorting}
                searchable
                pageable
                pageSizes={[10, 25, 50]}
                columnExtensions={columnExtensions}
              />
            </Paper>
          </React.Fragment>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
}

export default PatientCareTeam
